import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.breakpoint = this.getBreakpoint();
        this.currentBreakpoint();
        this.observeWindow();
    }

    getBreakpoint() {
        return window.getComputedStyle(document.body, ':before').content.replace(/\"/g, '');
    }

    observeWindow() {
        let that = this;
        window.addEventListener('resize', function () {
            that.breakpoint = that.getBreakpoint();
            that.currentBreakpoint();
        }, false);
    }

    currentBreakpoint() {
        switch(this.breakpoint) {
            case 'mobile':
                this.call('isSmallScreen', 'navigation');
              break;
            case 'tablet':
                this.call('isSmallScreen', 'navigation');

              break;
            case 'laptop':
                this.call('isBigScreen', 'navigation');
            break;
            case 'display':
                this.call('isBigScreen', 'navigation');
              break;
            default:
          }          
    }
}
