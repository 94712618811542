import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
        this.currentSection;
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            elMobile: this.el,
            smoothMobile: true,
            touchMultiplier: 4
        });

        this.scroll.on('call', (func,way,obj,id) => {
            this.call(func[0],{way,obj},func[1],func[2]);
        });
    }

    changeUrl(args) {
        let value = this.getData('url', args.obj.el);
        history.pushState({}, '', '/#' + value);
        this.call('setFocus', value, 'navigation');
        this.currentSection = value;
    }

    playVideo(args) {
        let video = args.obj.el
        if (video.paused) {
            video.currentTime = 0;
            video.play(); 
        } else { 
            video.pause(); 
        } 
    }

    toggleLazy(args) {
        let src = args.obj.el.dataset.src
        if(src.length) {            
            if(args.obj.el.tagName == "IMG") {
                args.obj.el.src = src;
            } else {
                args.obj.el.style.backgroundImage = `url(${src})`;
            }
        }
    }

    scrollTo(target) {
        this.scroll.scrollTo(target);
    }

    update() {
        this.scroll.update();
    }

    start() {
        this.scroll.start();
    }

    stop() {
        this.scroll.stop();
    }
}
