import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.navigation = this.$('navigation')[0];
        this.toggle = this.$('toggle')[0];

        this.events = {
            click: {
                button1: 'clickButton',
                button: 'clickButton',
                toggle: 'toggleNavigation',
                link: 'clickLink'
            }
        }
    }

    clickLink() {
        if (this.screen == 'small') {
            this.toggleNavigation();
        }
    }

    isSmallScreen() {
        this.screen = 'small';
        if(!this.navigation.classList.contains('collapsed')) {
            this.navigation.classList.add('collapsed');
            this.toggle.classList.remove('open');
        }
    }

    isBigScreen() {
        this.screen = 'big';
        this.navigation.classList.remove('collapsed');
        this.toggle.classList.remove('open');
    }

    toggleNavigation() {
        if(this.navigation.classList.contains('collapsed')) {
            this.$('content')[0].scrollTop = 0;
            this.navigation.classList.remove('collapsed');
            this.toggle.classList.add('open');
            if(window.location.hash == '#intro' || window.location.hash == '#identitaet') {
                this.call('scrollTo', '#mainWrapper', 'scroll');
            }
            this.call('stop', 'scroll');

        } else {
            this.navigation.classList.add('collapsed');
            this.toggle.classList.remove('open');
            this.call('start', 'scroll');
        }
    }

    setFocus(sectionName) {
        var allLinks = this.$('link');
        for (var element of allLinks) {
            element.classList.remove('focus');
        }

        var allButtons1 = this.$('button1');
        for (var element of allButtons1) {
            element.classList.remove('focus');
        }

        var allButtons2 = this.$('button');
        for (var element of allButtons2) {
            element.classList.remove('focus');
        }

        var newFocus = this.el.querySelector(`[href="#${sectionName}"]`);
        if(newFocus) {
            newFocus.classList.add('focus');
            var parent1 = newFocus.parentNode.parentNode.parentNode.firstChild;
            parent1.classList.add('focus');
            var parent2 = parent1.parentNode.parentNode.parentNode.firstChild;
            parent2.classList.add('focus');
        }
    }

    openTopLevel(currentList, wrapper) {
        var activeTopLevel = wrapper.querySelector('.navigation__list-level-2.active');
        if(activeTopLevel) {
            activeTopLevel.classList.remove('active');
            this.closeSubLevel(wrapper);
        }
        currentList.classList.add('active');
    }

    closeTopLevel(currentList, wrapper) {
        this.closeSubLevel(wrapper);
        currentList.classList.remove('active');
    }

    openSubLevel(currentList, wrapper) {
        this.closeSubLevel(wrapper);
        currentList.classList.add('active');
    }

    closeSubLevel(wrapper) {
        var activeSubLevel = wrapper.querySelector('.navigation__list-level-3.active');
        if(activeSubLevel) {
            activeSubLevel.classList.remove('active');
        }
    }

    clickButton(e) {
        var target = e.currentTarget;
        var currentParrent = this.parent('list', target); //li
        var wrapper = currentParrent.closest('.navigation__list-level-1');
        var currentList = currentParrent.children[1]; //ul

        var isActive = currentList.classList.contains('active');
        var isTopLevel = currentParrent.classList.contains('navigation__list__item-level-1');
        var isSubLevel = currentParrent.classList.contains('navigation__list__item-level-2');

        if (isActive && isTopLevel) {
            this.closeTopLevel(currentList, wrapper);
        }

        if (!isActive && isTopLevel) {
            this.openTopLevel(currentList, wrapper);
            this.openSubLevel(currentList.firstChild.lastChild, wrapper);
            var target = e.currentTarget.nextSibling.firstChild.lastChild.firstChild.firstChild.getAttribute("href");
            if (this.screen == 'big') {
                this.call('scrollTo', target, 'scroll');
            }
        }

        if (isActive && isSubLevel) {
            this.closeSubLevel(wrapper);
        }

        if (!isActive && isSubLevel) {
            this.openSubLevel(currentList, wrapper);
            var target = e.currentTarget.nextSibling.firstChild.firstChild.getAttribute("href");
            if (this.screen == 'big') {
                this.call('scrollTo', target, 'scroll');
            }
        }

        this.call('update', e, 'scroll');
    }
}