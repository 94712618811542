import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const loadedClass = '--loaded';
        
        const loader = this.$('loader')[0];
        const grid = this.$('grid')[0];
        const title = this.$('title')[0];
        const image = this.$('image')[0];
        const logo = this.$('logo')[0];
        const specification = this.$('specification')[0];
        const corporate = this.$('corporate')[0];
        const arrow = this.$('arrow')[0];
        const creator = this.$('creator')[0];

        loader.classList.add(loadedClass);
        grid.classList.add(loadedClass);
        title.classList.add(loadedClass);
        image.classList.add(loadedClass);
        logo.classList.add(loadedClass);
        specification.classList.add(loadedClass);
        corporate.classList.add(loadedClass);
        arrow.classList.add(loadedClass);
        creator.classList.add(loadedClass);
    }
}