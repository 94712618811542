import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                color: 'clickColor'
            }
        }
    }

    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }

    checkColorContrast(hex) {
        const rgb = this.hexToRgb(hex);
        const brightness = Math.round(
            ((parseInt(rgb.r) * 299) + (parseInt(rgb.b) * 587) + (parseInt(rgb.g) * 114)
        ) / 1000);
        const textColor = (brightness > 150) ? 'black' : 'white';
        return textColor;
    }

    clickColor(event) {
        const target = event.currentTarget;
        const hex = target.dataset.hex;
        this.copyToClipboard(hex);
    }

    copyToClipboard(string) {
            const element = document.createElement('textarea');
            element.value = string;        
            document.body.appendChild(element);
            element.select();
            document.execCommand('copy');
            document.body.removeChild(element);
            this.showCopyMessage(string);
    }

    showCopyMessage(text) {
        const textColor = this.checkColorContrast(text);
        const message = document.createElement('div');
        const messageText = document.createElement('span');
        
        message.classList.add('colors__message');
        message.style.backgroundColor = text;
        messageText.classList.add('colors__message__text');
        
        messageText.style.color = textColor;
        messageText.innerHTML = "Farbwert kopiert";
        
        message.appendChild(messageText);
        document.body.appendChild(message);
        
        setTimeout(function() {
            message.classList.add('--remove');
        }, 1);
        
        setTimeout(function() {
            document.body.removeChild(message);
        }, 1000);
    }
}
