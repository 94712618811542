import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        
        this.events = {
            click: {
                item: 'openVideo'
            }
        }
    }

    nextVideo(that) {
        var oldTarget = that.currentTarget;
        if(oldTarget.nextSibling) {
            that.currentTarget = oldTarget.nextSibling;
        } else {
            that.currentTarget = oldTarget.parentNode.firstChild;
        }
        this.changeVideo();
    }

    previousVideo(that) {
        var oldTarget = that.currentTarget;
        if(oldTarget.previousSibling) {
            that.currentTarget = oldTarget.previousSibling;
        } else {
            that.currentTarget = oldTarget.parentNode.lastChild;
        }
        this.changeVideo();
    }

    changeVideo() {
        this.name.innerHTML = this.currentTarget.firstChild.innerHTML;
        this.slug = this.currentTarget.dataset.illustrationSlug;
        this.source.setAttribute('src', `/${this.slug}-h264.mp4`);
        this.video.load();
    }

    closeVideo(that) {
        that.overlay.remove();
    }

    openVideo(event) {
        const that = this;
        this.currentTarget = event.currentTarget
        this.slug = this.currentTarget.dataset.illustrationSlug;

        this.overlay = document.createElement('div');
        this.overlay.setAttribute('class', 'illustration__overlay');

        this.inner = document.createElement('div');
        this.inner.setAttribute('class', 'illustration__overlay__inner');

        this.next = document.createElement('div');
        this.next.setAttribute('class', 'illustration__overlay__inner__next');
        var nextIcon = document.createElement('img');
        nextIcon.setAttribute('src', 'arrow-right-light-24.svg');
        this.next.append(nextIcon);

        this.previous = document.createElement('div');
        this.previous.setAttribute('class', 'illustration__overlay__inner__previous');
        var previousIcon = document.createElement('img');
        previousIcon.setAttribute('src', 'arrow-left-light-24.svg');
        this.previous.append(previousIcon);

        this.close = document.createElement('div');
        this.close.setAttribute('class', 'illustration__overlay__inner__close');
        var closeIcon = document.createElement('img');
        closeIcon.setAttribute('src', 'close-light-24.svg');
        this.close.append(closeIcon);

        this.name = document.createElement('span');
        this.name.setAttribute('class', 'illustration__overlay__inner__name');
        this.name.innerHTML = this.currentTarget.firstChild.innerHTML;

        this.video = document.createElement('video');
        this.video.setAttribute('class', 'illustration__overlay__inner__video');
        this.video.setAttribute('muted', 'true');
        this.video.setAttribute('autoplay', 'true');
        this.video.setAttribute('loop', 'true');

        this.source = document.createElement('source');
        this.source.setAttribute('src', `/${this.slug}-h264.mp4`);
        this.source.setAttribute('type', 'video/mp4');
        this.source.setAttribute('codecs', 'avc1');

        this.close.addEventListener('click', function() {
            that.closeVideo(that);
        });

        this.overlay.addEventListener('click', function(event) {
            if(event.target.classList.contains('illustration__overlay')) {
                that.closeVideo(that);
            }
        });

        this.next.addEventListener('click', function() {
            that.nextVideo(that);
        });

        this.previous.addEventListener('click', function() {
            that.previousVideo(that);
        });

        this.video.appendChild(this.source);
        this.inner.appendChild(this.close);
        this.inner.appendChild(this.previous);
        this.inner.appendChild(this.next);
        this.inner.appendChild(this.name);
        this.inner.appendChild(this.video);
        this.overlay.appendChild(this.inner);
        document.body.appendChild(this.overlay);
    }
}
