import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                tab: 'clickTab'
            }
        }
    }

    init() {
        const firstTab = this.$('tab')[0];
        firstTab.classList.add('--is-active');
    }

    clickTab(event) {
        var newTab = event.currentTarget;
        if(newTab.classList.contains('--is-active')) {
            return;
        }
        
        var oldTab = this.el.querySelector('.--is-active');
        oldTab.classList.remove('--is-active');
        newTab.classList.add('--is-active');

        var counter = 0;
        while( (newTab = newTab.previousSibling) != null )
            counter++;
        
        var oldContent = this.el.querySelector('.--is-open');
        oldContent.classList.remove('--is-open');
        var newContent = this.$('content')[counter];
        newContent.classList.add('--is-open');

        this.call('update', 'scroll');
    }
}
