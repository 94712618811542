import { module } from 'modujs';
import Glide from '@glidejs/glide';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.glider = new Glide(this.el, {
            type: 'carousel'
        });

        this.glider.mount();
    }
}
